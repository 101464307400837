import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { heading, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import Link from 'components/atoms/Link';
import { Ul, Ol, Li } from 'components/atoms/List';

const Title = styled.h1`
  font-weight: normal;
`;

const H3 = styled.h3`
  margin-bottom: 0.3em;
`;

const PaymentDescription = styled.p`
  margin-top: 0;
`;

const PaymentAndDeliveryPage = () => (
  <MainTemplate>
    <Helmet>
      <title>{title.paymentAndDelivery}</title>
      <meta name="keywords" content="" />
      <meta name="description" content="" />
    </Helmet>

    <Title>{heading.paymentAndDelivery}</Title>

    <h2>Оплата заказов</h2>
    <Ol>
      <Li>
        <H3>Наличный расчет</H3>
        <PaymentDescription>
          Оплата производится наличными в магазине при самовывозе.
        </PaymentDescription>
      </Li>
      <Li>
        <H3>Наложенный платеж</H3>
        <PaymentDescription>
          Оплата при получении в Вашем почтовом отделении, сумма заказа + тара+ почтовые расходы. 
          Данный способ доступен со 2-го заказа. (После полученного и оплаченного первого заказа.)
        </PaymentDescription>
      </Li>
      <Li>
        <H3>Предоплата</H3>
        <PaymentDescription>
          Оплата через банк после выставления счета на оплату, при отправки заказа транспортными
          компаниями.
        </PaymentDescription>
      </Li>
      <Li>
        <H3>Оплата на карту</H3>
        <PaymentDescription>
          Оплата через онлайн-банк на карту компании.
        </PaymentDescription>
      </Li>
    </Ol>

    <h2>Доставка заказов</h2>
    <p>Компания «Золотая Камея» осуществляет доставку следующими способами:</p>
    <Ol>
      <Li>
        <H3>Доставка почтой России</H3>
        <Ul>
          <Li>наложенный платеж (Только со 2-го заказа. После полученного и оплаченного первого заказа.)</Li>
          <Li>объявленная ценность, при 100% предоплате (экономичнее на 200-300 руб)</Li>
        </Ul>
        <p>
          <strong>Внимание!!! Важно!!!</strong>
        </p>
        <p>
          Если Вы заказали Ваш товар по почте России, то с момента заказа через 3-4 дня свяжитесь с
          нами по телефону с просьбой сообщить Вам номер отправления. После получения номера
          отправления Вы сможете самостоятельно отслеживать посылку на{' '}
          <Link href="https://www.pochta.ru/tracking" target="_blank">
            сайте почты России
          </Link>
          . Когда увидите что Ваша посылка прибыла в место вручения, идите в свое почтовое отделение
          с номером отправки посылки.
        </p>
        <p>
          В некоторых отделениях могут сказать &quot;Подождите несколько дней&quot; или &quot;Без
          извещения искать бандероль не будем&quot;. В этом случае проявите настойчивость и
          попросите поискать отправление.
        </p>
      </Li>
      <Li>
        <H3>Доставка транспортной компанией</H3>
        <p>Осуществляем доставку заказов (при 100% предоплате) транспортными компаниями.</p>
        <p>Ссылки на калькуляторы доставки транспортных компаний, с которыми мы работаем:</p>
        <Ol>
          <Li>
            <Link href="https://www.cdek.ru/calculator.html" target="_blank">
              СДЭК
            </Link>{' '}
          </Li>
          <Li>
            <Link href="https://nrg-tk.ru/client/calculator/?cityFrom" target="_blank">
              Энергия
            </Link>
          </Li>
          <Li>
            <Link href="https://www.dellin.ru/requests/" target="_blank">
              Деловые линии
            </Link>{' '}
            (для юридических лиц)
          </Li>
          <Li>
            <Link href="https://www.ponyexpress.ru/support/servisy-samoobsluzhivaniya/tariff/">
              Pony Express
            </Link>
          </Li>
          <Li>
            <Link href="https://www.baikalsr.ru/tools/calculator/" target="_blank">
              Байкал Сервис
            </Link>
          </Li>
          <Li>
            <Link
              href="http://www.cse.ru/sitecontent/city-mosrus/lang-rus/content/131/"
              target="_blank"
            >
              Куръер Сервис Экспресс
            </Link>
          </Li>
          <Li>
            <Link href="https://major-express.ru/calculator.aspx" target="_blank">
              Major Express
            </Link>
          </Li>
          <Li>
            <Link href="http://ae5000.ru/tarify-perevozok.html" target="_blank">
              Автотрейдинг
            </Link>
          </Li>
          <Li>
            <Link href="https://www.jde.ru/online/calculator.html" target="_blank">
              ЖелДорЭкспедиция
            </Link>
          </Li>
        </Ol>
      </Li>
    </Ol>
  </MainTemplate>
);

export default PaymentAndDeliveryPage;
